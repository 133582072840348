const settings = {
  markers: ['1','2','3','4','5','6','7','8','9','10','11','12']
};

// const date1 = new Date('2023-02-01');
// const date2 = new Date('2023-12-01');

// let date = new Date('2023-03-02');
// let month = date.getMonth();

// if (date < date1) {
// settings.markers = ['1'];
// } else if (date > date1 && date < date2) {

//   for (let i = 0; i < (11 - month); i++) {
//       settings.markers.pop();
//   }

// }


export default settings